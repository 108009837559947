/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  FORGOT_PASSWORD = '/forgot_password',
  LOGIN = '/',
  NOT_FOUND = '/not-found',
  RESET_PASSWORD = '/reset_password',
  SIGN_UP = '/register',
  TODO = '/todo',
  ACCOUNT_ADMIN = '/account/admin',
  ACCOUNT_SYSTEM = '/account/system',
  ACCOUNT_SETTING_NEWS = '/account/setting-news',
  DASHBOARD = '/dashboard',
  ADMIN_CLIENT = '/admin/client',
  ADMIN_CLIENT_CREATE = '/admin/client/create',
  ADMIN_CLIENT_EDIT = '/admin/client/:id/edit',
  ADMIN_CLIENT_DETAIL = '/admin/client/:id/detail',
  ADMIN_PARTNER = '/admin/partner',
  ADMIN_CREATE_PARTNER = '/admin/partner/create',
  ADMIN_EDIT_PARTNER = '/admin/partner/:partnerId/edit',
  ADMIN_DETAIL_PARTNER = '/admin/partner/:partnerId/detail',
  ADMIN_PROJECT = '/admin/project',
  ADMIN_CREATE_PROJECT = '/admin/project/create',
  ADMIN_EDIT_PROJECT = '/admin/project/:projectId/edit',
  ADMIN_DETAIL_PROJECT = '/admin/project/:projectId/detail',
  ADMIN_ORDER = '/admin/orders',
  ADMIN_ORDERED = '/admin/ordered',
  ADMIN_CREATE_ORDER = '/admin/orders/create',
  ADMIN_EDIT_ORDER = '/admin/orders/:orderId/edit',
  ADMIN_DETAIL_ORDER = '/admin/orders/:orderId/detail',
  ADMIN_ESTIMATE = '/admin/estimate',
  ADMIN_ESTIMATE_CREATE = '/admin/estimate/create',
  ADMIN_ESTIMATE_EDIT = '/admin/estimate/:id/edit',
  ADMIN_ESTIMATE_DETAIL = '/admin/estimate/:id/detail',
  ADMIN_ESTIMATE_PARTNER = '/admin/estimate-partner',
  ADMIN_ESTIMATE_PARTNER_DETAIL = '/admin/estimate-partner/:id/detail',
  ADMIN_USER = '/admin/user',
  ADMIN_BILLING = '/admin/billing',
  ADMIN_BILLING_CREATE = '/admin/billing/create/:project_id/:client_id',
  ADMIN_BILLING_EDIT = '/admin/billing/:id/edit',
  ADMIN_BILLING_DETAIL = '/admin/billing/:id/detail',
  ADMIN_BILLING_PARTNER = '/admin/billing-partner',
  ADMIN_BILLING_PARTNER_DETAIL = '/admin/billing-partner/:id/detail',
  ADMIN_CONTRACT = '/admin/contract',
  ADMIN_CONTRACT_PARTNER = '/admin/contract-partner',
  ADMIN_INFORMATION = '/admin/information',
  CLIENT_ESTIMATE = '/client/estimate',
  CLIENT_ESTIMATE_DETAIL = '/client/estimate/:id/detail',
  CLIENT_BILLING = '/client/billing',
  CLIENT_BILLING_DETAIL = '/client/billing/:id/detail',
  CLIENT_CONTRACT = '/client/contract',
  CLIENT_PROJECT = '/client/project',
  CLIENT_USER = '/client/user',
  CLIENT_PROJECT_DETAIL = '/client/project/:id/detail',
  PARTNER_USER = '/partner/user',
  PARTNER_ORDER = '/partner/orders',
  PARTNER_ORDER_DETAIL = '/partner/orders/:id/detail',
  PARTNER_PROJECT = '/partner/project',
  PARTNER_PROJECT_DETAIL = '/partner/project/:id/detail',
  PARTNER_ESTIMATE = '/partner/estimate',
  PARTNER_ESTIMATE_CREATE = '/partner/estimate/create',
  PARTNER_ESTIMATE_EDIT = '/partner/estimate/:id/edit',
  PARTNER_ESTIMATE_DETAIL = '/partner/estimate/:id/detail',
  PARTNER_CONTRACT = '/partner/contract',
  PARTNER_BILLING = '/partner/billing',
  PARTNER_BILLING_CREATE = '/partner/billing/create',
  PARTNER_BILLING_EDIT = '/partner/billing/:id/edit',
  PARTNER_BILLING_DETAIL = '/partner/billing/:id/detail',
  OPERATION = '/operation',
}
