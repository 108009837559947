import { Role } from 'src/models/role';
import { RoutePath } from 'src/router/routePath';
import { isLogged } from 'src/services/auth';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';

/**
 *
 * @param location
 * @param authRole
 *
 * Just only add path parent of feature as /client (don't need create, update, detail)
 *
 * */
export const authMiddleware = (authRole: Role | any, location: any) => {
  if (isLogged) {
    const authPath: any = {
      system_admin: [
        RoutePath.TODO,
        RoutePath.DASHBOARD,
        RoutePath.ACCOUNT_SYSTEM,
        RoutePath.ACCOUNT_ADMIN,
        RoutePath.ACCOUNT_SETTING_NEWS,
      ],
      admin: [
        RoutePath.TODO,
        RoutePath.DASHBOARD,
        RoutePath.ADMIN_CLIENT,
        RoutePath.ADMIN_PARTNER,
        RoutePath.ADMIN_PROJECT,
        RoutePath.ADMIN_ORDER,
        RoutePath.ADMIN_ORDERED,
        RoutePath.ADMIN_ESTIMATE,
        RoutePath.ADMIN_ESTIMATE_PARTNER,
        RoutePath.ADMIN_BILLING,
        RoutePath.ADMIN_CONTRACT,
        RoutePath.ADMIN_CONTRACT_PARTNER,
        RoutePath.ADMIN_USER,
        RoutePath.ADMIN_BILLING_PARTNER,
        RoutePath.ADMIN_INFORMATION,
      ],
      client: [
        RoutePath.TODO,
        RoutePath.DASHBOARD,
        RoutePath.CLIENT_ESTIMATE,
        RoutePath.CLIENT_BILLING,
        RoutePath.CLIENT_CONTRACT,
        RoutePath.CLIENT_USER,
        RoutePath.CLIENT_PROJECT,
      ],
      company: [
        RoutePath.TODO,
        RoutePath.DASHBOARD,
        RoutePath.PARTNER_USER,
        RoutePath.PARTNER_ORDER,
        RoutePath.PARTNER_PROJECT,
        RoutePath.PARTNER_ESTIMATE,
        RoutePath.PARTNER_CONTRACT,
        RoutePath.PARTNER_BILLING,
      ],
    };

    const pathname = location.pathname.split('/').slice(0, 3).join('/');

    if (
      location.pathname === RoutePath.RESET_PASSWORD &&
      location.search.includes('token')
    ) {
      Cookies.remove(ACCESS_TOKEN_KEY);
      window.location.replace(RoutePath.RESET_PASSWORD + location.search);
    }

    if (authRole && authRole.role_type) {
      return (
        authPath[authRole.role_type].filter((item: any) => item === pathname)
          .length > 0
      );
    }
  }

  return true;
};
